.progress-wrapper {
	width: 100%;
    margin-bottom: 20px;

	.progress-text {
		width: 100%;
		text-align: center;
		margin: 20px 0 5px 0;
	}

	.progressbar {
		width: 100%;
		height: 5px;
		background-color: #DCDCDC;
		position: relative;
		border-radius: 5px;

		.progressbar-inner {
			height: 5px;
			top: 0;
			left: 0;
			background-color: $color-main;
			border-radius: 5px;
		}
	}
}
