.no-phone-wrapper {
    height: calc(100vh - 60px);
    overflow-y: auto;

    .nophone-content {
        position: relative;
        @extend .center-vertical;
        margin-bottom: 20px;

        @include media-query('tablet'){
            top: auto;
            left: auto;
            transform: none;
        };

        .text {
            margin-bottom: 20px;
        }

        .slider-wrapper {
            position: relative;
            background-color: #DCF8C6;
            padding: 20px;
            border-radius: 15px;
            box-shadow: 0px 5px 10px rgba(0,0,0,0.1);

            ul.lines {
                list-style: none;
                color: white;
                position: absolute;
                top: 47px;
                width: 100%;
                margin: 0;
                font-size: 12px;
                pointer-events: none;
                left: 0;

                li {
                    position: absolute;

                    &:before {
                        display: none;
                    }
                }

                li:nth-child(1) {
                    left: 10%;
                }

                li:nth-child(2) {
                    left: 20%;
                }

                li:nth-child(3) {
                    left: 30%;
                }

                li:nth-child(4) {
                    left: 40%;
                }

                li:nth-child(5) {
                    left: 50%;
                    display: none;
                }

                li:nth-child(6) {
                    left: 60%;
                }

                li:nth-child(7) {
                    left: 70%;
                }

                li:nth-child(8) {
                    left: 80%;
                }

                li:nth-child(9) {
                    left: 90%;
                }
            }
        }

        .slider {
            width: 100%;
            margin: 20px auto;

            input[type=range] {
                -webkit-appearance: none;
                margin: 10px 0;
                width: 100%;
            }
            input[type=range]:focus {
                outline: none;
            }
            input[type=range]::-webkit-slider-runnable-track {
                width: 100%;
                height: 20px;
                cursor: pointer;
                animate: 0.2s;
                background: #A7D980;
                border-radius: 5px;
            }
            input[type=range]::-webkit-slider-thumb {
                height: 68px;
                width: 70px;
                border-radius: 7px;
                cursor: pointer;
                -webkit-appearance: none;
                margin-top: -25px;
                background: transparent;
                background-image: url('../images/fb-logo.png');
                background-repeat: no-repeat;
                background-size: contain;
                border: none;
            }
            input[type=range]:focus::-webkit-slider-runnable-track {
                background: #A7D980;
            }
            input[type=range]::-moz-range-track {
                width: 100%;
                height: 20px;
                cursor: pointer;
                animate: 0.2s;
                background: #A7D980;
                border-radius: 5px;
            }
            input[type=range]::-moz-range-thumb {
                height: 68px;
                width: 70px;
                // border-radius: 7px;
                background: transparent;
                cursor: pointer;
                background-image: url('../images/fb-logo.png');
                background-repeat: no-repeat;
                background-size: contain;
                border: none;
            }
            input[type=range]::-ms-track {
                width: 100%;
                height: 20px;
                cursor: pointer;
                animate: 0.2s;
                background: transparent;
                border-color: transparent;
                border-width: 40px 0;
                color: transparent;
            }
            input[type=range]::-ms-fill-lower {
                background: #A7D980;
                border-radius: 5px;
            }
            input[type=range]::-ms-fill-upper {
                background: #A7D980;
                border-radius: 5px;
            }
            input[type=range]::-ms-thumb {
                height: 68px;
                width: 70px;
                border-radius: 7px;
                background: transparent;
                cursor: pointer;
                background-image: url('../images/fb-logo.png');
                background-repeat: no-repeat;
                background-size: contain;
                border: none;
            }
            input[type=range]:focus::-ms-fill-lower {
                background: #A7D980;
            }
            input[type=range]:focus::-ms-fill-upper {
                background: #A7D980;
            }

        }

        .choice-wrapper {
            @include clearfix;
            width: 100%;

            .choice-left {
                float: left;
                width: 40%;
            }

            .choice-right {
                float: right;
                width: 40%;
                text-align: right;
            }
        }
    }
}
