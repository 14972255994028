.main-content {
    height: calc(100vh - 75px);
    @include clearfix;

    @include media-query('phone-wide'){
        margin: 20px -20px;
        padding: 0 20px;
        height: calc(100vh - 115px);
        overflow-y: auto;
    };

    .image-wrapper {
        height: 100%;
        float: left;
        width: 50%;
        padding-right: 30px;

        @include media-query('phone-wide'){
            width: 100%;
            height: auto;
            padding-right: 0;
        };

        img,
		video {
            position: relative;
            @extend .center-middle;
            max-height: 95%;
            max-width: 100%;

            @include media-query('phone-wide'){
                top: auto;
                left: auto;
                transform: none;
            };
        }

		video {
			box-shadow: 0 25px 30px -10px rgba(0,0,0,0.3);
		}
    }

    .text-wrapper {
        height: 100%;
        float: left;
        width: 100%;

        .text-inner {
            position: relative;
            @extend .center-middle;
            max-height: 95%;
            max-width: 100%;
            overflow: auto;

            @include media-query('phone-wide'){
                top: auto;
                left: auto;
                transform: none;
                max-height: none;
            };

            .title {
                position: relative;
                margin-bottom: 40px;

                &:after {
                    position: absolute;
                    content: '';
                    width: 50px;
                    height: 4px;
                    bottom: -20px;
                    left: 0;
                    background-color: $color-main;
                }
            }
        }
    }

    .image-wrapper+.text-wrapper {
        width: 50%;

        @include media-query('phone-wide'){
            width: 100%;
            height: auto;
        };
    }

}
