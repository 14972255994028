.resultpage-wrapper {
	position: relative;
	@extend .center-middle;
	max-height: 95%;
	max-width: 100%;
	overflow: auto;

	.title {
		position: relative;
		margin-bottom: 40px;
		text-align: center;
		display: block;
		font-size: 30px;

		&:after {
			position: absolute;
			content: '';
			width: 50px;
			height: 4px;
			bottom: -20px;
			@extend .center-horizontal;
			background-color: $color-main;
		}
	}

	.result-item {

		@include clearfix;

		.text-wrapper {
			float: left;
			width: 40%;

			.sub-title {
				color: $color-main;
			}
		}

	}
}

.result-bar-wrapper {
	float: left;
	width: 50%;
	margin-left: 5%;
	margin-right: 5%;
	position: relative;
	padding-top: 80px;

	.bar {
		width:100%;
		height: 10px;
		border-radius: 10px;
		background-color: #ccc;
		@include clearfix;
		position: relative;
		margin-bottom: 30px;

		.bar-inner {
			float: left;
			width: calc( 100% / 3 );
			height: 10px;

			&:first-child {
				background-color: #f26d47;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
			&:nth-child(2) {
				background-color: #faae00;
			}
			&:nth-child(3) {
				background-color: $color-main;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}

		.bar-indicator {
			position: absolute;
			height: 40px;
			width: 15px;
			border-radius: 5px;
			border: 5px solid #696969;
			box-shadow: 0 5px 10px rgba(0,0,0,0.1);
			background: white;
			top: -15px;

			span {
				position: absolute;
				top: -30px;
				@extend .center-horizontal;
			}
		}
	}

	.button {
		text-align: right;
		a {
			color: #535649;
			font-size: 14px;
			text-decoration: none;
			border: 1px solid #a3c627;
			border-radius: 5px;
			padding: 7px 15px;
		}
	}
}

.result-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: white;
	z-index: 99;
	opacity: 1;
	visibility: visible;
	transition: 0.3s all ease-in-out;

	&.is-hidden-overlay {
		top: 50vh;
		visibility: hidden;
		opacity: 0;
	}

	.result-detail {
		position: relative;
		@extend .center-vertical;
		max-height: 100vh;
		overflow: auto;
	}

	a.result-overlay-close {
	    color: white;
	    background: $color-main;
	    padding: 15px 20px;
	    border-radius: 100px;
	    position: absolute;
	    top: 10px;
	    text-decoration: none;
	    right: 10px;
	    font-weight: bold;
		z-index: 1;
	}

	.title {
		position: relative;
		margin-bottom: 60px;
		text-align: center;
		display: block;
		font-size: 30px;

		&:after {
			position: absolute;
			content: '';
			width: 50px;
			height: 4px;
			bottom: -20px;
			@extend .center-horizontal;
			background-color: $color-main;
		}
	}

	.result-detail-wrapper {
		@include clearfix;

		.image-wrapper {
			width: 40%;
			float:left;
			 img {
				 width: 100%;
				 display: block;
			 }
		}

		.text-wrapper {
			width:50%;
			float: left;
			margin-left: 5%;
			margin-right: 5%;

			.result-bar-wrapper {
				width: 100%;
			    margin: 0;
			    padding-top: 40px;
			    margin-bottom: 20px;
			}
		}

	}
}
