// @font-face {
//     font-family: 'ZOESTREEPFONT';
//     src: url('../fonts/ZOESTREEPFONT.otf') format('otf');
//     font-weight: normal;
//     font-style: normal;
// }
//
// @font-face {
//     font-family: 'montserrat';
//     src: url('../fonts/Montserrat-Regular.ttf') format('ttf');
//     font-weight: normal;
//     font-style: normal;
// }
//
// @font-face {
//     font-family: 'montserrat';
//     src: url('../fonts/Montserrat-Italic.ttf') format('ttf');
//     font-weight: normal;
//     font-style: italic;
// }
//
// @font-face {
//     font-family: 'montserrat';
//     src: url('../fonts/Montserrat-Medium.ttf') format('ttf');
//     font-weight: bold;
//     font-style: normal;
// }
//
// @font-face {
//     font-family: 'montserrat';
//     src: url('../fonts/Montserrat-MediumItalic.ttf') format('ttf');
//     font-weight: bold;
//     font-style: italic;
// }

@import url('https://fonts.googleapis.com/css?family=Montserrat');
