aside#bottom-bar {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: white;
	border-top: #eee;
	padding: 10px 0;

	@include media-query('tablet'){
		height: 60px;
	};

	.button-group {

		width: 100%;
		@include clearfix;

		a {
			width: 50%;
			display: block;
			margin: 0 auto;
		}

		&.three-btn {
			a {
				width: 32.66%;
				margin-right: 1%;
				float: left;

				&:nth-child(3) {
					margin-right: 0;
				}
			}
		}

		&.two-btn {
			a {
				width: 49.5%;
				margin-right: 1%;
				float: left;

				&:nth-child(2) {
					margin-right: 0;
				}
			}
		}
	}

	a {

	}
}
