body {
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	background-color: #ffffff;
	background-image: linear-gradient(180deg, #ffffff 25%, #e4e4e4 100%);
	width: 100vw;
	height: calc(100vh - 60px);
	overflow: hidden;

}

// *:not(html) {
//     -webkit-transform: translate3d(0, 0, 0);
// 	transform: translate3d(0, 0, 0);
// }

.container {
	max-width: 1140px;
	margin: 0 auto;
	line-height: 150%;
	font-size: 16px;
	padding: 0 20px;
	a {
		color: $color-main;
		position: relative;
		&:hover {
			opacity: 0.7;
		}
	}
	h1 {
		font-size: 400%;
		line-height: 130%;
		word-break: break-word;
	}
	h2,h3 {
		word-break: break-word;
	}
	blockquote {
		background: #eee;
		margin: 0;
		padding: 10px 30px;
		font-size: 120%;
		font-style: italic;
		line-height: 150%;
		margin: 20px 0px;
	}
	table {
		text-align: left;
		thead {
			background: #eee;
		}
		td, th {
			padding: 0;
			padding: 10px;
		}
	}
	img,
	video {
	    max-width: 100%;
	    height: auto;
	}
	.alignnone {
		margin: 5px 20px 20px 0;
	}

	.aligncenter,
	div.aligncenter {
		display: block;
		margin: 5px auto 5px auto;
	}

	.alignright {
		float:right;
		margin: 5px 0 20px 20px;
	}

	.alignleft {
		float: left;
		margin: 5px 20px 20px 0;
	}

	a img.alignright {
		float: right;
		margin: 5px 0 20px 20px;
	}

	a img.alignnone {
		margin: 5px 20px 20px 0;
	}

	a img.alignleft {
		float: left;
		margin: 5px 20px 20px 0;
	}

	a img.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.wp-caption {
		background: #fff;
		border: 1px solid #f0f0f0;
		max-width: 96%; /* Image does not overflow the content area */
		padding: 5px 3px 10px;
		text-align: center;
	}

	.wp-caption.alignnone {
		margin: 5px 20px 20px 0;
	}

	.wp-caption.alignleft {
		margin: 5px 20px 20px 0;
	}

	.wp-caption.alignright {
		margin: 5px 0 20px 20px;
	}

	.wp-caption img {
		border: 0 none;
		height: auto;
		margin: 0;
		max-width: 98.5%;
		padding: 0;
		width: auto;
	}

	.wp-caption p.wp-caption-text {
		font-size: 11px;
		line-height: 17px;
		margin: 0;
		padding: 0 4px 5px;
	}

	/* Text meant only for screen readers. */
	.screen-reader-text {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute !important;
		width: 1px;
		word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
	}

	.screen-reader-text:focus {
		background-color: #eee;
		clip: auto !important;
		clip-path: none;
		color: #444;
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000;
		/* Above WP toolbar. */
	}

	ul {
	    padding-left: 24px;

		li {
			ul {
			    padding-top: 10px;
			}
		}
	}

	ul {
		list-style: none;
	}

	ul li::before {
		content: "\2022";
		color: $color-main;
		font-weight: bold;
		display: inline-block;
		width: 1em;
		font-size: 20px;
	}

}

.primary-btn {
	background-color: $color-main;
	color: white !important;
	text-align: center;
	padding: 15px 20px;
	border-radius: 3px;
	text-decoration: none;

	@include media-query('tablet'){
		padding: 8px 20px;
	    font-size: 14px;
	};
}

.is-hidden {
	display: none !important;
}

@keyframes spin {
	to {
		@include transform(rotate(360deg));
	}
}

@keyframes spin {
	0% {
		@include transform(rotate(0deg));
	}

	100% {
		@include transform(rotate(360deg));
	}
}

.is-loading {
    color: transparent !important;

	&::before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		height: 20px;
		width: 20px;
		background: transparent;
		border-radius: 50%;
		border: 3px solid rgba(0,0,0,0.5);
		border-top-color: #bdbdbd;
		border-bottom: 3px solid #cacaca;
		animation: spin 1.5s linear infinite;
		@extend .center-middle;
		margin-top: -10px;
	}
}

/* Let's get this party started */
::-webkit-scrollbar {
	width: 6px;
}

::-moz-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(235, 98, 0, 0.3);
	background: #E0E0E0;
}

::-moz-scrollbar-track {
	background: rgba(235, 98, 0, 0.3);
	background: #E0E0E0;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(0,0,0,0.5);
}
::-moz-scrollbar-thumb {
	background: rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0,0,0,0.2);
}
::-moz-scrollbar-thumb:window-inactive {
	background: rgba(0,0,0,0.2);
}

.video-wrapper {
	button.vjs-big-play-button {
		@extend .center-middle;
	}
}

.potrait-warning {
    position: absolute;
    background-color: rgba(45, 58, 0, 0.9);
    color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: none;
    z-index: 999;

	.content {
		position: absolute;
		@extend .center-middle;

		img {
			width: 40px;
		}
	}
}

@media screen and (orientation: portrait) {
	.potrait-warning {
	    display: block;
	}
}
