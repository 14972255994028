header {

	.site-logo {
		background-color: $color-main;
		width: 70px;
		position: fixed;
		top: 0;
		right: 50px;
		height: 80px;
		border-radius: 0 0 50px 50px;
		z-index: 98;

		img {
			width: 60%;
			display: block;
			margin: 0 auto;
			padding-top: 20px;
		}

		@include media-query('desktop'){
            width: 40px;
			right: 20px;
			height: 45px;

			img {
				padding-top: 12px;
			}
        };


	}
}
