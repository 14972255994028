// COLORS

$color--background-body: #EFEFEF;
$color--background-secondary: #f5f5f5;
$color--background-screen: #f1f1f1;
$color--background-hr: #ccc;
$color--background-button: #e6e6e6;
$color--background-pre: #eee;
$color--background-ins: #fff9c0;
$color--background-main: #eb6200;

$color-main: #A3C627;
$color-text: #333C45;
