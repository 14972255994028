.progress-wrapper.is-hidden+.phone-wrapper {
	height: calc(100vh - 75px);
}

.phone-wrapper {
	height: calc(100vh - 135px);
    overflow: hidden;
	@include media-query('tablet'){
		margin: 0 -20px;
	};

	.phone-wrapper-inner {
		background-color: #EBE4DC;
		width: 100%;
		padding-top: 43%;
		border-radius: 100px;
		position: relative;
		border: 35px solid #3D3D3D;
		overflow: hidden;
	    box-shadow: 0 40px 30px -40px rgba(0,0,0,0.3);
		@extend .center-middle;

		@include media-query('tablet'){
		    border: none;
			border-radius: 0;
			height: 100%
		};

		// @include media-query-height('wp-phone'){
		//     padding-top: 55vh;
		// };

		@include media-query-height('690px'){
		    top: 0;
			transform: translate(-50%, 0);
			height: 100%;
		    padding-top: 0;
		};

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			border: 0;
			width: 100%;
			height: 100%;
			background-image: url('../images/bd-pattern.png');
			background-repeat: repeat;
		}

		&::after {
			content: '';
			position: absolute;
			width: 35px;
			height: 50%;
			background-color: #3D3D3D;
			left: -1px;
			border-radius: 0 30px 30px 0;
			@extend .center-vertical;

			@include media-query('tablet'){
			    display: none;
			};
		}
	}

	.phone-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.top-bar {
			width: 100%;
			background-color: #F7F7F7;
			border-bottom: 1px solid rgba(0,0,0,0.5);
			padding: 5px 40px;
			@include clearfix;

			@include media-query('tablet'){
			    display: none;
			};

			.item-left {
				float: left;

				.arrow {
					padding-top: 10px;
				}

				.avatar {
					position: absolute;
					height: 45px;
					width: auto;
					padding-left: 20px;
				}

				.name {
					padding-left: 80px;
				}
			}

			.item-right {
				float: right;
				img {
					padding-top: 13px;
					padding-right: 20px;
				}
			}
		}

		.phone-content-inner {
			padding-left: 60px;
		    padding-right: 40px;
		    padding-top: 20px;
		    padding-bottom: 20px;
		    max-height: calc( 100vh - 135px );
		    overflow: auto;

			p {
				margin: 0;
			}

			.question {
				display: block;
				margin-bottom: 20px;
				@include clearfix;

				.question-content {
					position: relative;
					max-width: 70%;
					background: white;
					border-radius: 15px;
					float: left;
					padding: 10px 15px;
					box-shadow: 0px 5px 10px rgba(0,0,0,0.1);

					&::after {
						position: absolute;
						content: '';
						background-image: url('../images/bubble-tip-white.svg');
						width: 20px;
						height: 20px;
						background-size: contain;
						background-repeat: no-repeat;
						left: -15px;
						bottom: 10px;
					}
				}
			}

			.answer {
				display: block;
				@include clearfix;

				.answer-content {
					position: relative;
					width: 80%;
					background: #DCF8C6;
					border-radius: 15px;
					float: right;
					padding: 10px 15px;
					box-shadow: 0px 5px 10px rgba(0,0,0,0.1);

					&::after {
						position: absolute;
						content: '';
						background-image: url('../images/bubble-tip-green.svg');
						width: 20px;
						height: 20px;
						background-size: contain;
						background-repeat: no-repeat;
						right: -15px;
						bottom: 10px;
					}
				}
			}

			.slider-wrapper {
				position: relative;

				ul.lines {
					list-style: none;
				    color: white;
				    position: absolute;
				    top: 7px;
				    width: 100%;
				    margin: 0;
				    font-size: 12px;
				    pointer-events: none;

					li {
						position: absolute;

						&:before {
							display: none;
						}
					}

					li:nth-child(1) {
						left: 10%;
					}

					li:nth-child(2) {
						left: 20%;
					}

					li:nth-child(3) {
						left: 30%;
					}

					li:nth-child(4) {
						left: 40%;
					}

					li:nth-child(5) {
						left: 50%;
						display: none;
					}

					li:nth-child(6) {
						left: 60%;
					}

					li:nth-child(7) {
						left: 70%;
					}

					li:nth-child(8) {
						left: 80%;
					}

					li:nth-child(9) {
						left: 90%;
					}
				}
			}

			.slider {
				width: 100%;
				margin: 50px auto;
			    margin-bottom: 20px;

				input[type=range] {
					-webkit-appearance: none;
					margin: 10px 0;
					width: 100%;
				}
				input[type=range]:focus {
					outline: none;
				}
				input[type=range]::-webkit-slider-runnable-track {
					width: 100%;
					height: 20px;
					cursor: pointer;
					animate: 0.2s;
					background: #A7D980;
					border-radius: 5px;
				}
				input[type=range]::-webkit-slider-thumb {
					height: 68px;
					width: 70px;
					border-radius: 7px;
					cursor: pointer;
					-webkit-appearance: none;
					margin-top: -25px;
					background: transparent;
					background-image: url('../images/fb-logo.png');
					background-repeat: no-repeat;
					background-size: contain;
					border: none;
				}
				input[type=range]:focus::-webkit-slider-runnable-track {
					background: #A7D980;
				}
				input[type=range]::-moz-range-track {
					width: 100%;
					height: 20px;
					cursor: pointer;
					animate: 0.2s;
					background: #A7D980;
					border-radius: 5px;
				}
				input[type=range]::-moz-range-thumb {
					height: 68px;
					width: 70px;
					// border-radius: 7px;
					background: transparent;
					cursor: pointer;
					background-image: url('../images/fb-logo.png');
					background-repeat: no-repeat;
					background-size: contain;
					border: none;
				}
				input[type=range]::-ms-track {
					width: 100%;
					height: 20px;
					cursor: pointer;
					animate: 0.2s;
					background: transparent;
					border-color: transparent;
					border-width: 40px 0;
					color: transparent;
				}
				input[type=range]::-ms-fill-lower {
					background: #A7D980;
					border-radius: 5px;
				}
				input[type=range]::-ms-fill-upper {
					background: #A7D980;
					border-radius: 5px;
				}
				input[type=range]::-ms-thumb {
					height: 68px;
					width: 70px;
					border-radius: 7px;
					background: transparent;
					cursor: pointer;
					background-image: url('../images/fb-logo.png');
					background-repeat: no-repeat;
					background-size: contain;
					border: none;
				}
				input[type=range]:focus::-ms-fill-lower {
					background: #A7D980;
				}
				input[type=range]:focus::-ms-fill-upper {
					background: #A7D980;
				}

			}

			.choice-wrapper {
				@include clearfix;
				width: 100%;

				.choice-left {
					float: left;
					width: 40%;
				}

				.choice-right {
					float: right;
					width: 40%;
					text-align: right;
				}
			}
		}
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

	.slider {
		margin-top: 0px;
	}

   .slider-wrapper ul.lines {
		top: 63px;
   }

   .choice-wrapper {
	   margin-top: -60px;
   }
}
