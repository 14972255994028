.popup-overlay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.7);
    z-index: 99;

	.popup-inner {
		position: absolute;
		@extend .center-middle;
		height: auto;
		max-height: 80vh;
		width: 600px;
		background-color: white;
		border-radius: 15px;
	    max-width: 90%;
	    border: 5px solid #eaeaea;

		.popup-content-wrapper {
		    overflow-y: auto;
		    max-height: calc(78vh - 70px);
			margin-right: -2px;
		}

		a.close-popup {
			text-decoration: none;
			font-size: 18px;
			font-family: arial;
			font-weight: 500;
			color: white;
			position: absolute;
			right: -15px;
			top: -15px;
			border-radius: 80px;
			padding: 8px 12px;
			background-color: $color-main;
		}

		h2.popup-title {
		    text-align: center;
			position: relative;
			margin-bottom: 40px;

			&:after {
				position: absolute;
				content: '';
				width: 50px;
				height: 4px;
				bottom: -20px;
				background-color: $color-main;
				@extend .center-horizontal;
			}
		}

		.button-group {
			padding: 10px;
		    background-color: #eaeaea;
			@include clearfix;

			a {
				display: inline-block;
				width: 100%;
			}

			&.two-btn {
				a {
					width: 48%;
					float: left;

					&:first-child {
						margin-right: 4%;
					}
				}
			}
		}
	}
}
